/* please do not add any additional sizes here without approval of UX */

/* TODO make icon sizes more consistent, lessen the amount of options in here */

@mixin md-icon-size($size: 24px) {
  font-size: $size !important;
  height: $size !important;
  width: $size !important;
}

.md-icon-12 {
  @include md-icon-size(12px);
}

.md-icon-14 {
  @include md-icon-size(14px);
}

.md-icon-16 {
  @include md-icon-size(16px);
}

.md-icon-18 {
  @include md-icon-size(18px);
}

.md-icon-20 {
  @include md-icon-size(20px);
}

.md-icon-22 {
  @include md-icon-size(22px);
}

.md-icon-24 {
  @include md-icon-size(24px);
}

.md-icon-36 {
  @include md-icon-size(36px);
}

.md-icon-38 {
  @include md-icon-size(38px);
}

.md-icon-60 {
  @include md-icon-size(60px);
}

.md-icon-80 {
  @include md-icon-size(80px);
}

.md-icon-94 {
  @include md-icon-size(94px);
}